<div class="flex flex-row grid h-full">

    <div class="sidebar h-screen col-2 flex flex-column justify-content-between ">
        <div>
            <h2>Sistema de <br> Conciliación <br> Laboral</h2>

            <div class="title">
                <p>{{userData?.name}} {{userData?.lastName}}</p>
                <p class="rol">{{userData?.rol}}</p>
            </div>

            <div class="buttons-container">
                <hr>
                <div class="button-container">
                    <div class="nav_option" [ngClass]="{ 'selected-button': selectedButton === 'home' }">
                        <div class="box" [ngClass]="{ 'box-selected': selectedButton === 'home' }">
                        </div>
                        <a routerLink="/main/home" (click)="selectButton('home')"
                            routerLinkActive="router-link-active"><img src="../../../../assets/img/sidebar/home.png"
                                alt="">
                            Home</a>
                    </div>
                </div>
                <div class="button-container">
                    <div class="nav_option" [ngClass]="{ 'selected-button': selectedButton === 'claims' }">
                        <div class="box" [ngClass]="{ 'box-selected': selectedButton === 'claims' }">
                        </div>
                        <a routerLink="/main/claims" class="claim-icon" (click)="selectButton('claims')"><img
                                src="../../../../assets/img/sidebar/reclamos.png" alt="">
                            {{claimButtonText}}</a>
                    </div>
                </div>
                <div *ngIf="commisionRol" class="button-container">
                    <div class="nav_option" [ngClass]="{ 'selected-button': selectedButton === 'audiences' }">
                        <div class="box" [ngClass]="{ 'box-selected': selectedButton === 'audiences' }">
                        </div>
                        <a routerLink="/main/audiences" class="claim-icon" (click)="selectButton('audiences')"><img
                                src="../../../../assets/img/sidebar/audience.png" alt="">
                                Audiencias</a>
                    </div>
                </div>
                <div class="button-container">
                    <div class="nav_option" [ngClass]="{ 'selected-button': selectedButton === 'config' }">
                        <div class="box" [ngClass]="{ 'box-selected': selectedButton === 'config' }">
                        </div>
                        <a routerLink="/main/configuration" (click)="selectButton('config')"><img
                                src="../../../../assets/img/sidebar/settings.png" alt="">Configuración</a>
                    </div>
                </div>
                <hr>
            </div>

            <div class="button-container">
                <div class="nav_option" [ngClass]="{ 'selected-button': selectedButton === 'logout' }">
                    <div class="box" [ngClass]="{ 'box-selected': selectedButton === 'logout' }">
                    </div>
                    <a (click)="logout()"><img src="../../../../assets/img/sidebar/logout.png" alt="">Cerrar sesión</a>
                </div>
            </div>
        </div>

        <div class="logo">
            <img src="../../../../assets/img/login/new-logo-encode-white.png" alt="">
        </div>
        <div class="flex justify-content-center hidden">
          <div class="hidden lg:flex flex-col justify-content-center pb-4 w-4">
            <p class="version">v.0.0.1</p>
          </div>
        </div>
    </div>
    <div class="router col-10 h-full">
        <router-outlet></router-outlet>
    </div>
</div>
