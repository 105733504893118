import { Entity } from "src/app/core/utils/entity";

export abstract class AudienceEntity extends Entity {
    constructor(
        public date: string,
        public time: string,
        public audienceTitle: string,
        public conciliator: string,
        public numberOfClaim: string,
        public proceedings: string,
        public claimetName: string,
        public claimetLastname: string,
        public claimedNameLastNameOrBusinessName: string,
        public audienceNumber?: string,
        public conciliatorId?: string,
        public claimId?: string,
        public claimetEmail?: string,
        public claimedEmail?: string,
        public claimetId?: string,
        public claimedId?: string,
        public id?: string,        
    ) {
        super();
    }
}