<div class="flex justify-content-center w-full relative p-4 h-full">
    <div class="grid w-full	">
        <div class=" col-9 flex flex-column align-items-center p-0">
            <ng-container *ngIf="claims">
                <div class="alert grid w-full">
                    <div class="col-12 flex flex-row align-items-center p-1 gap-2 ">
                        <img src="../../../../../../../assets/img/main/info-icon.png" alt="" class="info-icon">
                        <span>Tiene una audiencia fijada para el día 27 de Octubre de 2023 a las 09:30 AM</span>
                    </div>

                    <div class=" grid w-full ">
                        <div class="col-4  ">
                            <p-button label="Agendar en Calendar" class="btn-google">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                                    id="google-calendar">
                                    <path fill="#4285f4" d="M22,4.5v6H10v11H4V6.5a2.0059,2.0059,0,0,1,2-2Z"></path>
                                    <polygon fill="#ea4435" points="22 27.5 22 21.5 28 21.5 22 27.5"></polygon>
                                    <rect width="6" height="12" x="22" y="9.5" fill="#ffba00"></rect>
                                    <rect width="6" height="12" x="13" y="18.5" fill="#00ac47"
                                        transform="rotate(90 16 24.5)">
                                    </rect>
                                    <path fill="#0066da" d="M28,6.5v4H22v-6h4A2.0059,2.0059,0,0,1,28,6.5Z"></path>
                                    <path fill="#188038" d="M10,21.5v6H6a2.0059,2.0059,0,0,1-2-2v-4Z"></path>
                                    <path fill="#4285f4"
                                        d="M15.69,17.09c0,.89-.66,1.79-2.15,1.79a3.0026,3.0026,0,0,1-1.52-.39l-.08-.06.29-.82.13.08a2.3554,2.3554,0,0,0,1.17.34,1.191,1.191,0,0,0,.88-.31.8586.8586,0,0,0,.25-.65c-.01-.73-.68-.99-1.31-.99h-.54v-.81h.54c.45,0,1.12-.22,1.12-.82,0-.45-.31-.71-.85-.71a1.8865,1.8865,0,0,0-1.04.34l-.14.1-.28-.79.07-.06a2.834,2.834,0,0,1,1.53-.45c1.19,0,1.72.73,1.72,1.45a1.4369,1.4369,0,0,1-.81,1.3A1.52,1.52,0,0,1,15.69,17.09Z">
                                    </path>
                                    <polygon fill="#4285f4"
                                        points="18.71 12.98 18.71 18.79 17.73 18.79 17.73 14 16.79 14.51 16.58 13.69 17.95 12.98 18.71 12.98">
                                    </polygon>
                                </svg></p-button>
                        </div>

                        <div class="col-4  ">
                            <p-button label="Link a Google Meet" class="btn-google">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                    enable-background="new 0 0 141.7 141.7" viewBox="0 0 141.7 141.7" id="google-meet">
                                    <path fill="#00832d"
                                        d="M78,70.9l10.5,12l14.2,9.1l2.5-21l-2.5-20.5l-14.4,8L78,70.9z">
                                    </path>
                                    <path fill="#0066da"
                                        d="M16.8,90v17.9c0,4.1,3.3,7.4,7.4,7.4h17.9l3.7-13.5L42.2,90l-12.3-3.7L16.8,90z">
                                    </path>
                                    <path fill="#e94235" d="M42.2,26.4L16.8,51.7l13,3.7l12.3-3.7l3.6-11.6L42.2,26.4z">
                                    </path>
                                    <path fill="#2684fc" d="M42.2,51.7H16.8V90h25.3V51.7z"></path>
                                    <path fill="#00ac47"
                                        d="M118.8,37.1l-16.2,13.3V92l16.2,13.3c2.4,1.9,6,0.2,6-2.9V40C124.9,36.9,121.3,35.1,118.8,37.1z M78,70.9V90H42.2v25.3h53.1c4.1,0,7.4-3.3,7.4-7.4V92L78,70.9z">
                                    </path>
                                    <path fill="#ffba00"
                                        d="M95.3,26.4H42.2v25.3H78v19.1l24.7-20.5V33.8C102.7,29.7,99.4,26.4,95.3,26.4z">
                                    </path>
                                </svg></p-button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="grid w-full home-shadow flex flex-column">
                <div class="col-12  ">
                    <ng-container >
                        <div *ngIf="activeClaims" class=" grid w-full flex flex-row align-items-center justify-content-between mb-4">
                            <div class="col-6 tittle">
                                <h3>Últimos reclamos laborales</h3>
                            </div>
                            <div class="col-6 text-right">
                                <p-button class="btn-new-claim" label="Nuevo reclamo laboral" icon="pi pi-plus"
                                    routerLink="/main/newLaboralClaim"></p-button>
                            </div>
                        </div>
                        <app-claims-table [userData]="userData" (activeClaims)="isActiveClaims($event)"></app-claims-table>
                    </ng-container>
                </div>
            </div>
        </div>

       
    </div>
</div>