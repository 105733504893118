import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClaimEntity } from '../../../domain/entities/claim.entity';
import { ClaimModel } from '../../../data/models/claim.model';
import { ClaimUseCase } from '../../../domain/usecases/get-claim.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';

@Component({
  selector: 'app-claim-view',
  templateUrl: './claim-view.component.html',
  styleUrls: ['./claim-view.component.css']
})
export class ClaimViewComponent implements OnInit {
  claimId?: string | null;
  claim?: ClaimEntity;

  constructor(private route: ActivatedRoute,
    private claimUseCase: ClaimUseCase) { }

  ngOnInit(): void {
    this.claimId = this.route.snapshot.paramMap.get('id');
    this.getClaim();
  }

  async getClaim(): Promise<void> {
    let result = await this.claimUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;       
  }

  getStatusClass(status: string | undefined): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft';
      case ClaimStatus.PENDING:
        return 'status-pending';
      case ClaimStatus.REFUSED:
        return 'status-refused';
      case ClaimStatus.FINALIZED:
        return 'status-finalized';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process';
      case ClaimStatus.AUDIENCE_SET:
        return 'status-audience-set';
      default:
        return '';
    }
  }


}
