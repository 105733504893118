import { UseCase } from "src/app/core/utils/usecase";
import { AudienceEntity } from "../entities/audience.entity";
import { Failure } from "src/app/core/utils/failure";
import { AudienceRepositoryBase } from "../repositories/aucience-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class CreateAudienceUseCase implements UseCase<AudienceEntity, string | Failure> {
    constructor(private audienceRepository: AudienceRepositoryBase) {}
    
    async execute(param: AudienceEntity): Promise<string | Failure> {
        return await this.audienceRepository.createAudience(param);
    }
}