import { AudiencePaginationParams } from "../../domain/entities/audience-pagination-params.entity";

export class AudiencePaginationParamsModel extends AudiencePaginationParams {
    
    override getPath(): string {
        let url: string = '';
        
        if (this.userId) {
            url += `${this.userId}?`;
        } else {
            url += '?';
        }
        
        url += `pagination.IsPaginated=true`
        url += `&pagination.Page=${this.page}`
        url += `&pagination.AmountRegistersPage=${this.quantity}`
        return url;
    }
    override toJson(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

}