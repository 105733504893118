import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { NavComponent } from './core/components/nav/nav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { HttpServiceBase } from './core/services/http.service';
import { HttpService } from './core/services/http.implementation';
import { AuthModule } from './features/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainModule } from './features/main/main.module';
import { PrimengModule } from './core/components/primeng/primeng.module';
import { MessageService } from 'primeng/api';
import { SharedModule } from './core/shared/shared.module';
import { ProvinceRepositoryBase } from './core/services/location/domain/repositories/province-base.repository';
import { ProvinceRepository } from './core/services/location/data/repositories/province.repository';
import { ProvinceRemoteDataSource, ProvinceRemoteDataSourceBase } from './core/services/location/data/data-sources/province-remote.datasource';
import { GetProvinceUseCase } from './core/services/location/domain/usecases/get-province.usecase';
import { GetLocationsUseCase } from './core/services/location/domain/usecases/get-locations.usecase';

registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    PrimengModule,
    HttpClientModule, 
    MainModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule  
  ],
  exports: [SharedModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    {provide: HttpServiceBase, useClass: HttpService},
    {provide: ProvinceRepositoryBase, useClass: ProvinceRepository},
    {provide: ProvinceRemoteDataSourceBase, useClass: ProvinceRemoteDataSource},
    GetProvinceUseCase, 
    GetLocationsUseCase,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
