import { environment } from "src/environments/environment.dev";
import { UserData } from "../../domain/entities/user-data.entity";
import { HttpServiceBase } from "src/app/core/services/http.service";
import { UserDataModel } from "../models/user-data.model";
import { Injectable } from "@angular/core";
import { LocalStorageServiceBase } from "src/app/core/services/localStorage-serviceBase";
import { HttpHeaders } from "@angular/common/http";
import { Failure } from "src/app/core/utils/failure";

export abstract class UserRemoteDataSourceBase {
    abstract getMyData(): Promise<UserData>;
    abstract getAllConciliators(): Promise<UserData[] | Failure>;
}

@Injectable()
export class UserRemoteDataSource extends UserRemoteDataSourceBase {

    private url: string = `${environment.apiUrl}/User`;

    constructor(private httpService: HttpServiceBase,
        private localStorageService: LocalStorageServiceBase) {
        super();
    }

    override async getMyData(): Promise<UserData> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.get(`${this.url}/my-data`, headers);
        let map = new Map<string, any>(Object.entries(result.get('response')));
        return UserDataModel.fromJson(map);
    }

    override async getAllConciliators(): Promise<UserData[] | Failure> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.get(`${this.url}/get_all_conciliators`, headers);
        return result.get('response');
    }
}