import { Failure } from "src/app/core/utils/failure";
import { AudienceEntity } from "../entities/audience.entity";
import { AudiencePaginationParams } from "../entities/audience-pagination-params.entity";
import { AudiencePagination } from "../entities/audience-pagination.entity";
import { DeleteAudienceEntity } from "../entities/delete-audience.entity";

export abstract class AudienceRepositoryBase {
    abstract getAudience(id: string): Promise<AudienceEntity | Failure>;
    abstract getPaginationAudiences(params: AudiencePaginationParams): Promise<AudiencePagination | Failure>;
    abstract createAudience(audience: AudienceEntity): Promise<string | Failure>;
    abstract deleteAudience(deleteAudience: DeleteAudienceEntity): Promise<string | Failure>;
    abstract updateAudience(audience: AudienceEntity): Promise<string | Failure>;
    abstract getNextAudienceOfConciliator(id: string): Promise<AudienceEntity | Failure>;
    abstract getNextSetAudience(): Promise<AudienceEntity | Failure>;

}