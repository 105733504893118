import { Entity } from "src/app/core/utils/entity";

export abstract class UserData extends Entity {
    constructor(
        public id: string,
        public rol: string,
        public name: string,
        public lastName: string,
        public cuil: string,
        public gender: string,
        public phone: string,
        public birthDate: Date,
        public civilStatus: string,
        public province: string,
        public city: string,
        public street: string,
        public number: number,
        public floorApartment: string,
        public postalCode: number,
        public company: string,
        public companyCuil: string,
        public email: string,
        public tower?: string,
        public claims?: string[],
    ) {
        super();
    }
}