<div class="">
    <p-toast />
    <p-confirmDialog #cd>
        <ng-template pTemplate="headless" let-message>
            <div class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round ">
                <span class="col-12 font-semibold text-xl ">
                    {{ message.header }}
                </span>
                <div class="col-12 flex align-items-center justify-content-center gap-3">
                    <i class="pi pi-exclamation-circle text-lg"></i>
                    <p class="">{{ message.message }}</p>
                </div>
                <div class="col-12 flex gap-3 justify-content-end">
                    <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar " style="border-color: #263245;
                    background-color: white;
                    color: #263245;
                    font-size: 0.8rem;">
                    </button>
                    <button pButton label="Eliminar reclamo" (click)="cd.accept()" class="btn-siguiente " style="
                        background-color: #C93B3B;
                        border-color: #C93B3B;
                        font-size: 0.8rem;">
                    </button>
                </div>
            </div>
        </ng-template>
    </p-confirmDialog>
</div>

<div class="col-12">
    <ng-container *ngIf="!userClaims" class="empty-home-container">
        <div class="flex flex-column align-items-center">
            <img id="image" src="../../../../../../assets/img/main/file-searching.svg" alt="" />
            <p>Ud. no ha generado ningún reclamo <br> o no tiene fecha de audiencia fijada</p>
            <p-button class="btn-new-claim" label="Nuevo reclamo laboral" icon="pi pi-plus"
                routerLink="/main/newLaboralClaim"></p-button>
        </div>
    </ng-container>
</div>

<div *ngIf="userClaims" class="grid w-full relative p-3">
    <p-table (onLazyLoad)="loadRecords($event)" [tableStyle]="{ 'min-width': '100%'}" [value]="userClaims"         
        currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}" class="col-12">
        <ng-template pTemplate="header">
            <tr class="font-bold">
                <th class="col-12 sm:col-6 md:col-3">Reclamado</th>
                <th class="col-12 sm:col-6 md:col-3">Expediente</th>
                <th class="col-12 sm:col-6 md:col-3">Fecha de inicio</th>
                <th class="col-12 sm:col-6 md:col-2">Estado</th>
                <th class="col-12 sm:col-6 md:col-1"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-claim>
            <tr class="table-row">
                <td class="col-12 sm:col-6 md:col-3">{{ claim.nameLastNameOrBusinessNameClaimet
                    }}</td>
                <td class="col-12 sm:col-6 md:col-3">{{ claim.proceedings }}</td>
                <td class="col-12 sm:col-6 md:col-3">{{ claim.startDate | date: 'dd/MM/yyyy' }}
                </td>
                <td class="col-12 sm:col-6 md:col-2">
                    <p [ngClass]="getStatusClass(claim.status)">{{ claim.status | claimStatusDisplay }}</p>
                </td>
                <td class="col-12 sm:col-6 md:col-1 text-right">
                    <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                    <button type="button" pButton icon="pi pi-ellipsis-v"
                        (click)="selectMenuItemsByRol(claim.status); menu.toggle($event); selectClaim(claim.id)"
                        class="p-button-rounded p-button-text"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator class="col-12" *ngIf="!homeView" (onPageChange)="changePage($event)" [first]="first" [rows]="defaultRows"
        [totalRecords]="totalQuantity" [rowsPerPageOptions]="[5, 10, 25, 50]">
    </p-paginator>
</div>