<section class="flex justify-content-center w-full relative p-4 pb-1 ">
    <div class="grid w-full flex justify-content-center w-full relative">
        <div class=" col-9 flex flex-column">
            <div class="notification-sign p-2 border-round col-12">
                <div class="flex flex-column">
                    <span class="col-12 text-xl pb-0 font-semibold">Bienvenidos a SICOL - Un Sistema de la Comisión
                        Paritaria</span>
                    <span class="col-12 text-xl pt-1 font-semibold">Permanente de Relaciones Laborales</span>
                </div>
                <div class="grid p-2">
                    <div class="col-6">
                        <div *ngIf="!isConciliatorRol" class="col-12 flex flex-row align-items-center p-1 gap-2 ">
                            <i class="pi pi-clock gap-2 "></i>
                            <span *ngIf="!nextSetAudience" class="">No tiene audiencias fijadas</span>
                            <span *ngIf="nextSetAudience" class="">Próxima audiencia <span class="changeColor">{{nextAudienceDate | date: 'd \'de\' MMMM' : 'es' | titlecase }}</span> a las <span class="changeColor">{{nextSetAudience.time}}hs.</span></span>
                        </div>
                        <div *ngIf="isConciliatorRol" class="col-12 flex flex-row align-items-center p-1 gap-2 ">
                            <i class="pi pi-clock gap-2 "></i>
                            <span *ngIf="!nextAudienceOfConciliator" class="">No tiene audiencias fijadas</span>
                            <span *ngIf="nextAudienceOfConciliator" class="">Próxima audiencia <span class="changeColor">{{nextAudienceDate | date: 'd \'de\' MMMM' : 'es' | titlecase }}</span> a las <span class="changeColor">{{nextAudienceOfConciliator.time}}hs.</span></span>
                        </div>
                        <div class="col-12 flex flex-row align-items-center p-1 gap-2 ">
                            <i class="pi pi-file gap-2 "></i>
                            <span *ngIf="newClaims == 0">No tiene reclamos laborales nuevos</span>
                            <span *ngIf="newClaims != 0"> Tiene <span class="changeColor">{{newClaims}} reclamos</span> nuevos</span>
                        </div>

                    </div>
                    <div class="col-6 flex justify-content-end align-items-center gap-2">
                        <p-button label="Ver audiencias" class="" routerLink="/main/audiences">
                        </p-button>
                        <p-button label="Ver reclamos" class="" routerLink="/main/claims">
                        </p-button>
                    </div>

                </div>

            </div>
            <div class="nextAudiences grid home-shadow flex flex-column mt-4 col-12">
                <span class="col-12 text-xl pb-0 font-semibold">Próximas audiencias fijadas</span>
                <div *ngIf="!showAudiences" class="flex flex-column justify-content-center align-items-center col-12">
                    <img id="image" src="../../../../../../assets/img/main/file-searching.svg" alt="" class="col-5" />
                    <p class="mt-4 text-lg font-normal">Aún no tiene audiencias fijadas</p>
                </div>
                <app-audience-table *ngIf="showAudiences"></app-audience-table>
            </div>

        </div>
        <div class="calendar col-3">

        </div>
    </div>

</section>

<section class="flex flex-column justify-content-center w-full relative p-4 pt-0">
    <div class="grid home-shadow flex flex-column mt-4 col-12">
        <span class="col-12 text-xl pb-0 font-semibold">Últimos reclamos laborales</span>
        <ng-container *ngIf="newClaims == 0; else showClaimantName">
            <div class="flex flex-column justify-content-center align-items-center">
                <img id="image" src="../../../../../../assets/img/main/file-searching.svg" alt="" />
                <p class="mt-4 text-lg font-normal">Aún no tiene reclamos laborales para visualizar</p>
            </div>
        </ng-container>
        <ng-template #showClaimantName>
            <app-claims-comision-table></app-claims-comision-table>
        </ng-template>
    </div>
</section>
