import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainBaseComponent } from './presentation/pages/main-base/main-base.component';
import { MyLaborClaims } from './presentation/components/myLaborClaims/myLaborClaims.component';
import { HomeComponent } from './presentation/components/home/home.component';
import { ConfigurationComponent } from '../user/presentation/pages/configuration/configuration.component';
import { ClaimViewComponent } from '../claims/presentation/components/claim-view/claim-view.component';
import { ClaimComponent } from '../claims/presentation/components/claim/claim.component';
import { AudiencesComponent } from './presentation/components/audiences/audiences.component';


const routes: Routes = [
  {
    path: '', component: MainBaseComponent,
    children: [
      { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'claims', component: MyLaborClaims},
      { path: 'newLaboralClaim', component: ClaimComponent},
      { path: 'configuration', component: ConfigurationComponent},
      { path: 'claims/:id', component: ClaimViewComponent},
      { path: 'audiences', component: AudiencesComponent},
      { path: 'claim/update/:id', component: ClaimComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}