import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GetMyDataUseCase } from 'src/app/features/user/domain/usecases/get-my-data.usecase';
import { UserService } from 'src/app/features/user/services/user.service';
import { Failure } from '../../utils/failure';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { filter } from 'rxjs';
import { UserRol } from '../../enums/user-rol.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  selectedButton: string = 'home';
  userData?: UserData;
  userRol?: string;
  claimButtonText: string = 'Mis reclamos laborales';
  commisionRol: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private getMyDataUseCase: GetMyDataUseCase
  ) { }

  async ngOnInit(): Promise<void> {
    this.getMe();

    this.router.events.subscribe(value => {
      this.updateSelectedButton(this.router.url);
    });
    
  }

  async getMe(): Promise<void> {
    let result = await this.getMyDataUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userService.userData = result;
    this.userService.userData$.next(result);
    this.userService.userRol$.next(result.rol);
    this.userData = result;
    this.userRol = result.rol;
    this.setCliamButtonText();
  }

  updateSelectedButton(url: string) {
    switch (true) {
      case url.includes('/main/home'):
        this.selectedButton = 'home';
        break;
      case url.includes('/main/claims'):
        this.selectedButton = 'claims';
        break;
      case url.includes('/main/configuration'):
        this.selectedButton = 'config';
        break;
      case url.includes('/main/audiences'):
        this.selectedButton = 'audiences';
        break;
      default:
        this.selectedButton = 'home';
    }
  }

  setCliamButtonText(): void {
    if (this.userData?.rol === UserRol.ADMINISTRATOR || this.userData?.rol === UserRol.COMISSION || this.userData?.rol === UserRol.COMISSION_MEMBER ||
      this.userData?.rol === UserRol.CONCILIATOR) {
      this.claimButtonText = 'Reclamos laborales';  
      this.commisionRol = true;
    }
  }

  selectButton(button: string) {
    this.selectedButton = button;
  }

  logout() {
    localStorage.removeItem('user_rol');
    localStorage.removeItem('user_id');
    localStorage.removeItem('claims_quantity');
    this.commisionRol = false;
    this.router.navigate(['/auth/login'])
  }
}
