import { Failure, UnhandledFailure } from "src/app/core/utils/failure";
import { AudienceEntity } from "../../domain/entities/audience.entity";
import { AudienceRepositoryBase } from "../../domain/repositories/aucience-base.repository";
import { Injectable } from "@angular/core";
import { AudienceRemoteDataSourceBase } from "../data-source/audience-remote.datasource";
import { AudiencePaginationParams } from "../../domain/entities/audience-pagination-params.entity";
import { AudiencePagination } from "../../domain/entities/audience-pagination.entity";
import { DeleteAudienceEntity } from "../../domain/entities/delete-audience.entity";

@Injectable()
export class AudienceRepository extends AudienceRepositoryBase {

    constructor(private audienceRemote: AudienceRemoteDataSourceBase) {
        super();
    }

    override async getAudience(id: string): Promise<AudienceEntity | Failure> {
        try {
            return await this.audienceRemote.getAudience(id);
        } catch (error: any) {
            return new UnhandledFailure(error.message, error.code);
        };
    }

    override async getPaginationAudiences(params: AudiencePaginationParams): Promise<AudiencePagination | Failure> {
        try {
            return await this.audienceRemote.getPaginationAudiences(params);
        } catch (error: any) {
            return new UnhandledFailure(error.message, error.code);
        }
    }

    override async createAudience(audience: AudienceEntity): Promise<string | Failure> {
        try {
            return await this.audienceRemote.createAudience(audience);
        } catch (error: any) {
            return new UnhandledFailure(error.message, error.code);
        }
    }

    override async deleteAudience(deleteAudience: DeleteAudienceEntity): Promise<string | Failure> {
        try {
            return await this.audienceRemote.deleteAudience(deleteAudience);
        } catch (error: any) {
            return new UnhandledFailure(error.message, error.code);
        }
    }

    override async updateAudience(audience: AudienceEntity): Promise<string | Failure> {
        try {
            return await this.audienceRemote.updateAudience(audience);
        } catch (error: any) {
            return new UnhandledFailure(error.message, error.code);
        }
    }

    override async getNextAudienceOfConciliator(id: string): Promise<AudienceEntity | Failure> {
        try {
            return await this.audienceRemote.getNextAudienceOfConciliator(id);
        } catch (error: any) {
            return new UnhandledFailure(error.message, error.code);
        };
    }

    override async getNextSetAudience(): Promise<AudienceEntity | Failure> {
        try {
            return await this.audienceRemote.getNextSetAudience();
        } catch (error: any) {
            return new UnhandledFailure(error.message, error.code);
        };
    }

}