import { Failure, UnhandledFailure } from "src/app/core/utils/failure";
import { UserData } from "../../domain/entities/user-data.entity";
import { UserRepositoryBase } from "../../domain/repositories/user-base.repository";
import { UserRemoteDataSourceBase } from "../data-sources/user-remote.datasource";
import { Injectable } from "@angular/core";
@Injectable()
export class UserRepository extends UserRepositoryBase {

    constructor(private userRemoteDataSource: UserRemoteDataSourceBase) {
        super();
    }

    override async getMyData(): Promise<UserData | Failure> {
        try {
            return await this.userRemoteDataSource.getMyData();
        } catch (error: any) {
            return new UnhandledFailure(error, 500);
        }
    }

    override async getAllConciliators(): Promise<UserData[] | Failure> {
        try {
            return await this.userRemoteDataSource.getAllConciliators();
        } catch (error: any) {
            return new UnhandledFailure();
        }
    }
}