import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from 'src/app/core/components/primeng/primeng.module';
import { MainRoutingModule } from './main-routing.module';
import { MainBaseComponent } from './presentation/pages/main-base/main-base.component';
import { MyLaborClaims } from './presentation/components/myLaborClaims/myLaborClaims.component';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { HomeComponent } from './presentation/components/home/home.component';
import { UserRemoteDataSourceBase, UserRemoteDataSource } from '../user/data/data-sources/user-remote.datasource';
import { UserRepositoryBase } from '../user/domain/repositories/user-base.repository';
import { UserRepository } from '../user/data/repositories/user.repository';
import { GetMyDataUseCase } from '../user/domain/usecases/get-my-data.usecase';
import { ClaimRemoteDataSource, ClaimRemoteDataSourceBase } from '../claims/data/data-source/claim-remote.datasource';
import { ClaimRepositoryBase } from '../claims/domain/repositories/claim-base.repository';
import { ClaimRepository } from '../claims/data/repositories/claim.repository';
import { GetPaginatedClaimsUseCase } from '../claims/domain/usecases/get-paginated-claims.usecase';
import { ClaimsTableComponent } from '../claims/presentation/components/claims-table/claims-table.component';
import { ConfigurationComponent } from '../user/presentation/pages/configuration/configuration.component';
import { ResetPasswordComponent } from '../user/presentation/components/reset-password/reset-password.component';
import { ClaimViewComponent } from '../claims/presentation/components/claim-view/claim-view.component';
import { ClaimUseCase } from '../claims/domain/usecases/get-claim.usecase';
import { UpdateClaimUseCase } from '../claims/domain/usecases/update-claim.usecase';
import { DeleteClaimUseCase } from '../claims/domain/usecases/delete-claim.usecase';
import { ClaimComponent } from '../claims/presentation/components/claim/claim.component';
import { CreateClaimUseCase } from '../claims/domain/usecases/create-claim.usecase';
import { ClaimantDetailsFormComponent } from '../claims/presentation/components/claim/claimant-details-form/claimant-details-form.component';
import { ClaimedDetailsFormComponent } from '../claims/presentation/components/claim/claimed-details-form/claimed-details-form.component';
import { ClaimDetailsFormComponent } from '../claims/presentation/components/claim/claim-details-form/claim-details-form.component';
import { AudienceRemoteDataSource, AudienceRemoteDataSourceBase } from '../audiences/data/data-source/audience-remote.datasource';
import { AudienceRepositoryBase } from '../audiences/domain/repositories/aucience-base.repository';
import { AudienceRepository } from '../audiences/data/repositories/audience.repository';
import { AudienceUseCase } from '../audiences/domain/usecases/get-audience.usecase';
import { AudienceTableComponent } from '../audiences/presentation/components/audience-table/audience-table.component';
import { GetPaginationAudiencesUseCase } from '../audiences/domain/usecases/get-pagination-audiences.usecase';
import { CreateAudienceUseCase } from '../audiences/domain/usecases/create-audience.usecase';
import { DeleteAudienceUseCase } from '../audiences/domain/usecases/delete-audience.usecase';
import { UpdateAudienceUseCase } from '../audiences/domain/usecases/update-audience.usecase';
import { ClaimsComisionTableComponent } from '../claims/presentation/components/claims-comision-table/claims-comision-table.component';
import { ComisionHomeComponent } from './presentation/components/home/comision-home/comision-home.component';
import { AudiencesComponent } from './presentation/components/audiences/audiences.component';
import { SimpleRolHomeComponent } from './presentation/components/home/simple-rol-home/simple-rol-home.component';
import { GetPaginatedAllClaimsUseCase } from '../claims/domain/usecases/get-pagination-all-claims.usecase';
import { GetFileUseCase } from '../claims/domain/usecases/get-file.usecase';
import { GetPendingQuantityClaimsUseCase } from '../claims/domain/usecases/get-pending-quantity-claims.usecase';
import { GetAllConciliatorsUseCase } from '../user/domain/usecases/get-all-conciliators.usecase';
import { UpdateClaimStatusUseCase } from '../claims/domain/usecases/update-claim-status.usecase';
import { UpdateClaimConciliatorUseCase } from '../claims/domain/usecases/update-claim-conciliator.usecase';
import { GetNextAudienceOfConciliatorUseCase } from '../audiences/domain/usecases/get-next-audience-of-conciliator.usecase';
import { GetNextSetAudienceUseCase } from '../audiences/domain/usecases/get-next-set-audience.usecase';
import { ClaimStatusDisplayPipe } from 'src/app/core/pipe/claim-status-display.pipe';


@NgModule({
  declarations: [
    MainBaseComponent,
    HomeComponent,
    MyLaborClaims,
    ClaimsTableComponent,
    ConfigurationComponent,
    ResetPasswordComponent,
    ClaimViewComponent,
    ClaimComponent,
    ClaimantDetailsFormComponent,
    ClaimedDetailsFormComponent,
    ClaimDetailsFormComponent,
    AudienceTableComponent,
    ClaimsComisionTableComponent,
    ComisionHomeComponent,
    AudiencesComponent,
    SimpleRolHomeComponent,
    ClaimStatusDisplayPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PrimengModule,
    MainRoutingModule,
    SharedModule,
    FormsModule
  ],
  providers: [
    { provide: UserRemoteDataSourceBase, useClass: UserRemoteDataSource },
    { provide: UserRepositoryBase, useClass: UserRepository },
    GetMyDataUseCase,
    GetAllConciliatorsUseCase,
    {provide: ClaimRemoteDataSourceBase, useClass:ClaimRemoteDataSource},
    {provide: ClaimRepositoryBase, useClass:ClaimRepository},
    GetPaginatedClaimsUseCase,
    ClaimUseCase,
    UpdateClaimUseCase,
    DeleteClaimUseCase,
    CreateClaimUseCase,
    GetFileUseCase,
    GetPaginatedAllClaimsUseCase,
    GetPendingQuantityClaimsUseCase,
    UpdateClaimStatusUseCase,
    UpdateClaimConciliatorUseCase,
    {provide: AudienceRemoteDataSourceBase, useClass:AudienceRemoteDataSource},
    {provide: AudienceRepositoryBase, useClass:AudienceRepository},
    AudienceUseCase,
    GetPaginationAudiencesUseCase,
    CreateAudienceUseCase,
    DeleteAudienceUseCase,
    UpdateAudienceUseCase,
    GetNextAudienceOfConciliatorUseCase,
    GetNextSetAudienceUseCase
  ],
})
export class MainModule { }