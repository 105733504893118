import { Injectable } from '@angular/core';
import { UserData } from '../domain/entities/user-data.entity';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private data?: UserData;
  userRol$: Subject<string> = new Subject();
  userData$: Subject<UserData> = new Subject();
  
  constructor() { }

  get userData() : UserData | undefined  {
    return this.data;
  }

  set userData(userData: UserData) {
    this.data = userData;
    this.userData$.next(userData);
    this.userRol$.next(userData.rol) ;
  }

  get userRol(): string {
    return this.userRol;
  }
}
